$column: 100% / 12;
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	max: 1440px
) !default;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	max: 1380px
) !default;

$base-font:		'semplicitapro', 'Semplicita Pro', sans-serif;
$alt-font:		'alda', serif;
$ico-font:		'FontAwesome';

$white:			#fff;
$black:			#000;
$base:			#060616;
$alt:		  	#faa436;
$alt2:			#050617;
$gray:			#333333;
$table:    		#d1d5db;

$light:			300;
$normal:		400;
$medium:		500;
$sbold:			600;
$bold:			700;

$nav-bar-height:	126px;
// #06133a
// #FFA23C
// #F6BA7C
// #FF4E1B