.btn{
	display: inline-flex;
	justify-content: center;
	// padding: 15px 20px;
	align-items: center;
	background: $base;
	color: $white;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: $sbold;
	position: relative;
	z-index: 1;
	transition: .3s ease;
	border-radius: 0 !important;
	cursor: pointer;
	letter-spacing: .8px;
	border: 0;
	height: 40px;
	width: 170px;
	user-select: none;
	text-align: center;
	&:hover{
		background: $alt;
	}
	&--gray{
		font-weight: $light;
		background: #EEEEEE;
		color: $base;
	}
	&--cart {
		width:100%!important;
		height: 100%!important;
		background-color: #faa436!important;
	}
	&--alt{
		background: $alt;
		&:hover{
			background: $base;
		}
	}
	&--center{
		margin-left: 50%;
		transform: translateX(-50%);
	}
	&--404{
		border: 1px solid $base;
		justify-content: center;
		text-align: center;
		line-height: 1.5;
		height: initial;
		width: initial;
		padding: 10px 25px;
		&:hover{
			background: $white;
			color:$white;
		}
	}
}

a.checkout-button {
	width:100%!important;
	height: 100%!important;
	margin-bottom:0!important;
	font-size:18px;
	text-transform: uppercase;
	background-color: #faa436!important;
	color: white;
	font-weight: $medium;
	letter-spacing: 1.5px;
}
button[class="btn-cart"]:disabled {
	@extend .btn;
	background-color: $gray!important;
}
.woocommerce a.button{
	@extend .btn;
}